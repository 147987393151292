import React from "react"
import Layout from "../../../components/Layout/Layout"
import Container from "../../../components/Layout/Container"
import MedicalHistory from "../../../components/Intake/SelfRequest/MedicalHistory"

export default ({ location }) => (
  <Layout
    title="Medical History"
    subtitle="Help us understand your needs better"
    seoTitle="Medical History (Self-request Teleconsult)"
    display={{ footer: false, helpCenterBanner: false }}
  >
    <Container isCentered desktop={6} fullhd={6}>
      <MedicalHistory
        backRoute="/intake/self"
        nextRoute="/intake/self/assessment"
        location={location}
      />
    </Container>
  </Layout>
)
