import React, { useContext } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"

import Section from "components/Elements/Section"
import ActionButtons from "components/Elements/ActionButtons"
import Message from "components/Elements/Message"

import { AppContext } from "../../../../context/AppContext"
import { generateFormField } from "components/Elements/Form/services/form"
import { kesslerValidationSchema } from "../../utils/formData"
import kessler6 from "../../utils/kessler6.json"
import FormLikertSlider from "../../../Elements/Form/FormLikertSlider"
import FormLikertRadio from "../../../Elements/Form/FormLikertRadio"

const Kessler = ({ backRoute, nextRoute, values }) => {
  const { state, dispatch } = useContext(AppContext)
  const { selfRequest } = state

  const likertOptions = ["Always", "Often", "Sometimes", "Rarely", "Never"]

  const handleSubmit = async (values) => {
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        selfRequest: {
          ...selfRequest,
          notes: values.notes || "",
          kessler6: values,
        },
      },
    })
    navigate(nextRoute)
  }

  const kesslerQuestions = kessler6.map((formField) => {
    if (!formField?.referenceAnswer) {
      return generateFormField({
        formField,
        values,
        formFields: kessler6,
      })
    }
  })

  return (
    <Section title="In the past month, how often did you feel...">
      {kesslerQuestions}
    </Section>
  )
}

export default Kessler
