import React, { Fragment } from "react"
import { generateFormField } from "../../../Elements/Form/services/form"
import pastConsultsFields from "../../utils/pastConsultsFields.json"

const PastConsults = ({ values }) => {
  const pastConsultsQuestions = pastConsultsFields.map((formField) => {
    if (!formField?.referenceAnswer) {
      return generateFormField({
        formField,
        values,
        formFields: pastConsultsFields,
      })
    }
  })
  return (
    <Fragment>
      <hr className="has-background-light" />
      {pastConsultsQuestions}
      <hr className="has-background-light" />
    </Fragment>
  )
}

export default PastConsults
