import React, { Fragment } from "react"
import FormTextArea from "../../../Elements/Form/FormTextarea"

import { generateFormField } from "../../../Elements/Form/services/form"
import medsAndDiagnosisFields from "../../utils/medsAndDiagnosisFields.json"

const PastMedsAndDiagnoses = ({ values }) => {
  const medsAndDiagnosisQuestions = medsAndDiagnosisFields.map((formField) => {
    if (!formField?.referenceAnswer) {
      return generateFormField({
        formField,
        values,
        formFields: medsAndDiagnosisFields,
      })
    }
  })

  return (
    <Fragment>
      {medsAndDiagnosisQuestions}
      <br />
    </Fragment>
  )
}

export default PastMedsAndDiagnoses
