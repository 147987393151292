import React, { useContext } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"
import { GATSBY_SKIP_FORMS } from "gatsby-env-variables"

import Section from "../../Elements/Section"
import ActionButtons from "../../Elements/ActionButtons"
import PastConsults from "./MedicalHistory/PastConsults"
import PastMedsAndDiagnoses from "./MedicalHistory/PastMedsAndDiagnoses"
import Message from "components/Elements/Message"

import { AppContext } from "../../../context/AppContext"
import { generateFormField } from "../../Elements/Form/services/form"
import { checklistValidationSchema } from "../utils/formData"
import raisedConcern from "../utils/raisedConcern.json"
import wishedToBeDead from "../utils/wishedToBeDead.json"
import Kessler from "./Assessment/Kessler"

const MedicalHistory = ({ backRoute, nextRoute, location }) => {
  const { state, dispatch } = useContext(AppContext)
  const { selfRequest } = state

  const handleSubmit = async (values) => {
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        selfRequest: { ...selfRequest, ...values },
      },
    })
    if (values?.wishedToBeDead.includes("Yes")) {
      navigate("/intake/self/assessment")
    } else {
      navigate("/intake/self/schedule")
    }
  }

  return (
    <Formik
      initialValues={selfRequest}
      validationSchema={!GATSBY_SKIP_FORMS && checklistValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors }) => {
        const raisedConcernQuestion = raisedConcern.map((formField) => {
          if (!formField?.referenceAnswer) {
            return generateFormField({
              formField,
              values,
              formFields: raisedConcern,
            })
          }
        })

        const wishedToBeDeadQuestion = wishedToBeDead.map((formField) => {
          if (!formField?.referenceAnswer) {
            return generateFormField({
              formField,
              values,
              formFields: wishedToBeDead,
            })
          }
        })

        return (
          <Form key="MedicalHistory">
            <Section title="Tell us what kind of support you need">
              <Message color="light">
                We understand that questions from here onwards may be sensitive.
                Rest assured that we ask the following to know how best to help
                you, and that all data remain confidential between you and your
                care providers only.
              </Message>
              {raisedConcernQuestion}
              <PastConsults values={values} />
              <PastMedsAndDiagnoses values={values} />
              <Kessler values={values} />
              <hr className="has-background-light" />
              {wishedToBeDeadQuestion}
            </Section>
            {Object.keys(errors).length > 0 ? (
              <Message color="warning">
                You may have missed some required fields. Please scan through
                the form and check if your information is complete.
              </Message>
            ) : null}
            <ActionButtons
              submit={{ label: "Continue" }}
              back={{ label: "Back to Personal Details", link: backRoute }}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default MedicalHistory
